<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";
import {dateUtil} from "../../../../helpers/date-util";
import {errorCatcher} from "../../../../helpers/error-catcher";

export default {

  components: {
    CustomCardSubtitle,
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,
      depot: null,
      depotId: "",

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        inputPage: "",

        items: []
      },

      form: {
        name: ""
      },

      visibleModal: false
    }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('depot-sections.title', { name: this.depot?.name || '' }),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "name", label: this.$t('message.name')},
        {key: "createdAt", label: this.$t('table.created-at'), formatter: dateUtil.asDateTime},
        {key: "updatedAt", label: "Ostatnia aktualizacja", formatter: dateUtil.asDateTime},
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    async loadDepot() {
      this.depotId = this.$route.params.id

      try {
        const {data} = await axios.get(`/depot/${this.$route.params.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.depot = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadDepotSections() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/depot/section/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            depotId: this.depotId
          }
        });

        this.table.items = data.depotSections
        this.table.totalRows = data.count;
        this.table.rows = data.count;
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      return this.table.items;
    },

    openModal() {
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.form = {
        name: ""
      }
    },

    editDepotSection(depotSection) {
      this.form = Object.assign({}, depotSection)
      this.openModal()
    },

    createOrEditDepotSection() {
      const json = {
        ...this.form,
        depotId: this.depotId
      }

      axios.put(`/depot/section`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(this.$t(this.depot.id ? "Edytowano magazyn" : "Utworzono nowy magazyn"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.$refs.table.refresh()
        this.hideModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    }

  },

  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  async beforeMount() {
    await this.loadDepot()
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('depot-sections.title', { name: depot?.name || '' })" :items="getItems()" />
    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="button-items mb-2">
                  <router-link to="/dashboard/core/depots" tag="a" class="btn btn-success">
                    Wróć do listy hurtowni
                  </router-link>
                </div>

                <custom-card-subtitle title="Akcje"/>

                <div class="col-xl-12">
                  <b-button variant="success" @click="openModal">Utwórz nowy magazyn</b-button>
                </div>
              </div>

              <ecat-table
                  ref="table"
                  :table="table"
                  :fields="getFields()"
                  :items="loadDepotSections"
                  :pagination-top="true"
              >
                <template v-slot:action="{ item }">
                  <div class="button-items">
                    <b-button variant="primary" class="btn-sm" @click="editDepotSection(item)">Edytuj</b-button>
                  </div>
                </template>
              </ecat-table>
            </div>
          </div>
        </div>
      </div>

    <b-modal
        id="modal-1"
        v-model="visibleModal"
        hide-footer
        :title="form.id ? 'Edytowanie magazynu' : 'Tworzenie nowego magazynu'"
        title-class="font-18"
        @esc="hideModal"
        @hide="hideModal">
      <div class="form-group">
        <label>Nazwa magazynu</label>
        <input v-model="form.name" type="text" class="form-control"/>
      </div>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="createOrEditDepotSection">
          Zapisz
        </b-button>

        <b-button variant="danger" class="my-3" @click="hideModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

  </Layout>
</template>